//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { List, Icon } from "vant";
import WalletList from "./component/WalletList";
import {
  getHomeInfo,
  fetchNftList,
  fetchAccountProfile,
} from "../../api/wallet/index";
import {
  NumberHide,
  isUserCenter,
  formatFiatCommify,
  isMobile,
  parseNFTData,
  coinFormartWalletSupportSymbol,
  formatAddress,
  formatTokenCommify
} from "../../utils/index";
import { nftIsSell } from '../../api/nft'
import content_hashUtils from "../../utils/content-hash";
import { IPFS_PREVIEW_URL } from "../../utils/ipfs.js";
import axios from "axios";
import { mapState } from "vuex";

const stayTunedNetworks = ["4-2", "97-2", "230-2"];
import CoinAllSelect from "./component/CoinAllSelect";
import FuturesOverview from './futures/overview'
import user_base_head from "../../assets/img/user_base_head.png";
import accountProfileBanner from "../../assets/img/account_profile_banner.png";

import commify from "./../../filter/commify";

import BNP from "bignumber.js";

import projectConfig from "@/config";

import copy from "clipboard-copy"
import ImportToken from "@/common_modules/wallet-submodule/view/home/component/ImportToken.vue";

export default {
  name: "WalletOverview",
  components: {
    ImportToken,
    WalletList,
    List,
    Icon,
    CoinAllSelect,
    FuturesOverview
  },
  props: {
    activeTab: {
      type: String,
      default: "token",
    },
    others: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSupportNFTMarket: projectConfig.isSupportNFTMarket,
      userHead: user_base_head,
      accountProfileBanner,
      balanceSymbol: "ETH",
      dataList: [],
      pageSize: 20,
      listFlag: false,
      rate: null,
      exprize: null,
      canWithdrawal: null,
      refreshChainId: "",
      userCenter: isUserCenter(),
      hideSmall: false,
      loading: false,
      finished: false,
      noticeBanlan: {},
      tabId: "token",
      filter: {
        network: "",
        search: "",
        oldest: "desc",
      },
      nftList: [],
      nftPages: {
        current: 1,
        pageSize: 10,
        total: 0,
        hasNextPage: false,
      },
      loadingNft: false,
      finishedNft: false,
      // networkVal
      networkVal: "",
      // networkAndLayer: "",
      curToken: {},
      CoinSelectShow: false,
      networkChainLayer: "",
      queryAddress: null,
      total: null,

      accountDetail: {},
      chainValue: '',
      activeLayer: 'L1',
      isLoadFirst: true,
      // 是否显示import token的弹窗
      importTokenVisible: false
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    originChainId() {
      return this.$store.state.chainId || ''
    },
    accountIsCustody() {
      return this.$store.getters.accountIsCustody;
    },
    account() {
      return this.$store.getters.account || '';
    },
    currency() {
      return this.$store.state.user.currency;
    },
    currencySymbol() {
      return this.currency.symbol;
    },
    priceMap() {
      return this.$store.state.priceMap;
    },
    tokenList() {
      return this.dataList.map((item) => {
        // console.log("item.symbol:", item.symbol);
        // 计算法币价格。
        const price = this.priceMap[item.symbol] || 0;
        // console.log("price:", price);
        const totalAmount = item.total_amount.replace(/,/g, "");
        // console.log("totalAmount:", totalAmount);
        const currencyAmount = new BNP(totalAmount)
          .times(price);
        item.total_amount = formatTokenCommify((item.total_amount+'').replace(/\,/g, ''), item.show_scale)
        item.available_amount = formatTokenCommify((item.available_amount+'').replace(/\,/g, ''), item.show_scale)
        item.locked_amount = formatTokenCommify((item.locked_amount+'').replace(/\,/g, ''), item.show_scale)

        return {
          ...item,
          currency_amount_number: currencyAmount,
          currency_amount: formatFiatCommify(currencyAmount, item.show_scale),
        };
      }).sort((a, b) => {
        return (b.currency_amount_number - a.currency_amount_number) == 0 ? (a.symbol.localeCompare(b.symbol)) : ((b.currency_amount_number - a.currency_amount_number) > 0 ? 1 : -1)
      }) || [];
      // 钱包首页排序
    },
    tokenFiatCalcTotal() {
      return this.tokenList.reduce((prev, cur) => {
        return new BNP(cur.currency_amount_number).plus(prev);
      }, 0);
    },
    tokenFiatTotal() {
      return this.tokenList.reduce((prev, cur) => {
        return new BNP(cur.currency_amount_number).plus(prev);
      }, 0).toFixed(2);
    },
    tokenFiatTotalStr() {
      return commify(this.tokenFiatTotal);
    },
    totalETH() {
      const ETHPprice = this.priceMap['ETH'] && (1 / this.priceMap['ETH']) || 0;
      return new BNP(this.tokenFiatCalcTotal)
        .times(ETHPprice)
        .toFixed(8);
    },
    totalETHStr() {
      return commify(this.totalETH);
    },
    areaCode() {
      return "";
    },
    curIp() {
      return "";
    },
    smallFlag() {
      return this.$store.state.wallet.smallFlag;
    },
    balanceFlag() {
      return this.$store.state.wallet.balanceFlag;
    },
    currencyList() {
      let result = this.tokenList;
      if (this.curToken.symbol) {
        result = result.filter(
          (item) => item.symbol === this.curToken.symbol
        );
      }
      if (this.hideSmall) {
        result = result.filter((item) => {
          return parseFloat(item.total_amount) > 0;
        });
      }
      return result;
    },
    showNFTEmpty() {
      return !this.loadingNft && this.nftList.length === 0;
    },
    emptyText() {
      let network = this.networkVal // = this.filter.network
      return stayTunedNetworks.includes(network)
        ? this.$t("wallet_common_stay_tuned")
        : this.$t("wallet_common_no_record_found");
    },

    isSelf() {
      if (!this.queryAddress) {
        return true;
      }
      return (
        this.userInfo.uid && this.userInfo.uid.toLowerCase() ===
        this.queryAddress.toLowerCase()
      );
    },
    supportLayer2() {
      return this.$store.getters.supportLayer2;
    },
    supportNFTUNSELL() {
      return this.$store.getters.supportNFTUNSELL;  
    },
    supportNFT() {
      return this.$store.getters.supportNFT || this.$store.getters.supportNFTUNSELL;
    },
    supportBridge() {
      return this.$store.getters.supportBridge;
    },
    supportPerpetual() {
      return this.$store.getters.supportPerpetual;
    },
    networksOverview() {
      return this.$store.getters.networksOverview;
    },
    walletSupportTokens() {
      return this.$store.state.siteConfig.walletSupportTokens
    },
    networksFilterDulpName() {
      let networks = []
      let networkIds = []
      this.networksOverview.map(v => {
        let obj = Object.assign({}, v)
        obj.value = v.networkId
        obj.label = v.name.replace(/\-L\d/, '')
        if (!networkIds.includes(v.networkId)) {
          networkIds.push(v.networkId)
          networks.push(obj)
        }
      })
      this.isLoadFirst && this.formatDataFirst()
      this.isLoadFirst = false
      return [
        ...networks
      ]
    },
    networks() {
      return [
        ...this.networksOverview,
      ];
      // }
    },
    layerList() {
      let list = this.networks.filter(v => v.networkId == this.networkVal).map(v => v.layer) || []
      list.sort((a, b) => a.slice(1) - b.slice(1) > 0 ? 1 : -1)
      return [...list]
    },
    updatePriceTimer() {
      return this.$store.state.updatePriceTimer;
    },
    beBindWalletConnect() {
      return this.$store.state.user.userInfo.be_bind_wallet_connect;
    },
    walletType() {
      return this.$store.state.siteConfig.walletType;
    },
    networkAndLayer() {
      return parseInt(this.networkVal) + '-' + (this.activeLayer == 'L2' ? '2' : '1')
    },
    chainId() {
      const [chainId, layer] = this.networkAndLayer.split("-");
      return Number(chainId);
    },
    layer() {
      const [chainId, layer] = this.networkAndLayer.split("-");
      return Number(layer);
    }
  },
  watch: {
    // "$store.state.wallet.wallet"(val) {
    //     if (val && Object.keys(val).length && !this.queryAddress) {
    //         this.loadData(1);
    //     }
    // },
    "$store.state.user.currency"(val, oldVal) {
      if (val.name != oldVal.name && !this.queryAddress) {
        this.loadData(2);
      }
    },
    activeTab(val) {
      this.tabId = val;
    },
    // tabId(val) {
    //     this.$router.replace({
    //         query: {
    //             ...this.$route.query,
    //             tabId: val,
    //         },
    //     });
    // },
    $route: {
      handler(val) {
        // 从other-nft跳转到overview。
        this.loadData(3);
        this.fetchNftList();
      },
      immediate: false,
    },
    '$route.query': {
      deep: true,
      handler(val) {
        const { tabId, address } = val;
        this.tabId = tabId || 'token';
        this.queryAddress = address;
      }
    },
    '$route.params': {
      deep: true,
      handler(val) {
        const { tabId, address } = val;
        this.tabId = tabId || 'token';
        this.queryAddress = address;
      }
    },
    networkAndLayer(val, oldVal) {
      if(val != oldVal) {
        this.$store.commit('setChainIdAndLayerCache', this.networkAndLayer)
        this.onSelectChange()
      }
    },
    '$store.state.wallet.walletChainId'(val, oldVal) {
      if(val && val != oldVal) {
        this.init()
      }
    },
    originChainId(val, oldVal) {
      if(val && val != oldVal) {
        this.onChangeChain(val)
        this.init()
      }
    },
    account(val, oldVal) {
      if (!oldVal && val) {
        this.init()
      }
    }
  },
  async created() {
    this.init()
  },
  mounted() {
    this.refreshChainId = "";
  },
  methods: {
    async init() {
      const { tabId, address } = this.$route.params;
      this.tabId = tabId || this.$route.query.tabId || this.activeTab || 'token';
      this.queryAddress = address;
      if (address) {
        this.fetchAccountProfile(address);
      } else {
        await Promise.all([
          this.setNetworkList(),
          this.$store.dispatch("getContratMap"),
          // this.$store.dispatch("getLayer2TokenList", chainId).catch(e => {
          //     console.error("e", e);
          // }),
          this.loadData(4),
          this.fetchNftList()
        ]).catch(e => {
          console.error("e", e);
        });
      }
    },
    formatAddress,
    async fetchAccountProfile(account) {
      fetchAccountProfile(account).then(res => {
        this.accountDetail = res.data;
      }).catch(e => null);
    },
    async setNetworkList() {
      const chainIdAndLayerCache = this.$store.state.wallet.chainIdAndLayerCache
      let network = this.getSupporNetwork(chainIdAndLayerCache)
      this.networkVal = network.networkId
      this.activeLayer = this.layerList.length > 1 ? 'L1' : (this.layerList[0] || network.layer)
    },
    onChangeChain(val) {
      // TODO待处理
      let network = this.networksFilterDulpName.find(v => v.networkId == val)
      this.networkVal = network.networkId
      this.activeLayer = this.layerList.length == 2 ? 'L1' : this.layerList[0]
      this.$store.commit('setChainIdAndLayerCache', this.networkAndLayer)
      this.fetchTokenList();
      if (this.tabId == 'futures') {
        this.$refs.futuresHeader.refreshData(this.networkAndLayer)
        this.$refs.futuresContent.refreshData(this.networkAndLayer)
      }
    },
    onChangeToken(item) {
      this.curToken = item;
    },
    fetchTokenList() {
      console.log("this.networkAndLayer", this.networkAndLayer);
      let chainId = "",
        layer = "";
      if (this.networkAndLayer) {
        [chainId, layer] = this.networkAndLayer.split("-");
      }
      this.refreshChainId = chainId;
      this.loadData(6, layer);
    },
    async loadData(type, layer = null) {
      console.log('loadData---', type)
      this.pages = 30;
      let address = this.account;//this.walletData.getAddress();
      address = this.beBindWalletConnect && address || this.$store.state.user.userInfo.uid
      const currencyName = this.currency.name;
      if (!address) {
        this.listFlag = true;
        this.loading = false;
        this.finished = true;
        return
      };
      let params = {
        address,
        currency: currencyName,
        chain_layer_list: this.networkAndLayer,
      };
      console.log('load-params:', params)
      if (type != "refresh") this.listFlag = false;
      this.dataList = [];
      this.loading = true;
      this.clearPriceInterval();
      getHomeInfo(params)
        .then(async (res) => {
          if (res.code == 200) {
            this.canWithdrawal = res.data.can_withdrawal;
            const chainId = this.networkAndLayer.split('-')[0] || '0';
            let resList = res.data.coin_asset_list.map((v) => {
              let detail = this.$store.getters[
                "commonTokenDetail"
                ](v.symbol, chainId);
              console.log('commonTokenDetail:chainId:',  chainId, v.symbol, detail)
              if (!detail || !detail.decimals) {
                detail = this.$store.getters["commonTokenDetail"](v.symbol, this.$store.state.chainId)
                // detail = detail || this.$store.getters["commonTokenDetail"](v.symbol, '0')
                // detail = this.$store.getters["commonTokenDetail"](v.symbol, '0')
              }
              // console.log('detail:', detail);
              if (detail) {
                v.icon_url = detail.icon_url;
                v.name = detail.name;
                v.total_amount_number = v.total_amount.replace(
                  /\,/gi,
                  ""
                );
                v.show_scale = detail.show_scale;
              }
              return v;
            });
            console.log('resList', resList)
            // 有Import token功能后不需要通过 walletSupportTokens 做限制
            // this.dataList = coinFormartWalletSupportSymbol(resList, this.$store.state.siteConfig.walletSupportTokens)
            this.dataList = resList;
            this.$set(this, "dataList", this.dataList);
            this.$store.state.loading = false;
            await this.fetchTokenPrice();
            this.initPriceInterval();
          }
        }).finally(() => {
        this.listFlag = true;
        this.loading = false;
        this.finished = true;
      })
    },
    initPriceInterval() {
      this.clearPriceInterval();
      this.priceIntervalTimer = setInterval(() => {
        this.fetchTokenPrice();
      }, this.updatePriceTimer);
    },
    clearPriceInterval() {
      if (this.priceIntervalTimer) {
        clearInterval(this.priceIntervalTimer);
        this.priceIntervalTimer = null
      }
    },
    async fetchTokenPrice() {
      const symbols = ['ETH'].concat(this.dataList.map((item) => item.symbol));
      this.$store.dispatch("fetchTokenPrice", symbols.join(","));
    },
    goWalletDealCenter(params, type) {
      let path = "";
      let userCenter = this.userCenter;
      const chainId = this.$store.state.wallet.checkDataChanId;
      let query = {
        name: params ? params.name : "",
      };
      if (type !== "bridge" && chainId && chainId !== "") {
        query.chainId = chainId;
      }
      switch (type) {
        case "deposit":
          path = "/wallet/deposit";
          break;
        case "withdraw":
          path = "/wallet/withdraw/index";
          break;
        case "send":
          path = "/wallet/send";
          break;
        case "bridge":
          path = "/wallet/bridge";
          break;
        default:
          break;
      }
      this.$router.push({
        path: path,
      });
    },
    hideBalance() {
      this.$store.commit("setBalanceFlag", !this.balanceFlag);
    },
    goUrl(routeName) {
      this.$router.push({
        name: routeName,
        query: {
          chainId: this.networkVal
        }
      });
    },
    refresh(chainId, layer) {
      this.refreshChainId = chainId;
      this.loadData("refresh", layer);
    },
    hideSmallBalance() {
      this.$store.commit("setSmallFlag", !this.smallFlag);
    },
    formatNumber(value) {
      return NumberHide(value);
    },
    getChainId() {
      if (this.networkAndLayer) {
        try {
          return Number(this.networkAndLayer.split('-')[0]);
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    },
    getLayer() {
      if (this.networkAndLayer) {
        try {
          return this.networkAndLayer.split('-')[1];
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    },
    goBalance(item) {
      if (item.is_dividend_coin) {
        sessionStorage.setItem("dividend_icon", item.icon_url);
        this.$router.push({
          name: "trade-dividend",
          query: {
            symbol: item.symbol,
            name: item.name,
          },
        });
        return;
      }
      this.noticeBanlan = item;
      const chainId = this.getChainId();
      this.$router.push({
        name: "wallet-balance",
        params: {
          symbol: item.symbol,
          name: item.name,
        },
        query: {
          chainId,
          layer: this.getLayer(),
          total: item.total_amount,
          available: item.available_amount,
          locked: item.locked_amount,
          currency: item.currency_amount,
        }
      });
      return;
    },
    changeTab(key) {
      this.tabId = key;
      switch (this.tabId) {
        case 'futures':
          this.$nextTick(() => {
            this.$refs.futuresHeader.refreshData(this.networkAndLayer)
            this.$refs.futuresContent.refreshData(this.networkAndLayer)
          })
          break;
        default:
          break;
      }
    },
    onSearchNFT() {
      this.nftPages.current = 1;
      this.fetchNftList();
    },
    changeNftPage(page) {
      this.nftPages.current = page;
      this.fetchNftList();
    },

    onSelectChange() {
      this.nftPages.current = 1;
      this.nftList = [];
      this.fetchNftList();
    },

    updateNFTItem(item, index) {
      item.loaded = true;
      this.$set(this.nftList, index, item);
    },
    async getOneNFTDetail(baseItem,index) {

      const item = JSON.parse(JSON.stringify(baseItem));
      console.log("getOneNFTDetail item:", item);
      if (item.layer === 1) {
        let nftData = await parseNFTData(item.nft_data);
        const nftType = Object.prototype.toString.call(nftData);
        // console.log("nftData:", nftData);
        // console.log("nftType:", nftType);
        if (nftType === "[object String]") {
          try {
            item.nft_data_object = JSON.parse(nftData);
            item.nft_image = item.nft_data_object.image;
          } catch (e) {
            item.nft_data_object = {};
            item.nft_image = nftData;
          }
        } else if (nftType === "[object Object]") {
          item.nft_data_object = nftData;
          item.nft_image = nftData.image;
          item.pledgeAmount = this.getPledgeAmount(
            nftData.attributes
          );
        }
      }

      if (item.layer === 2) {
        const { content_hash } = item;
        const hash = "e30101701220" + content_hash.split("0x")[1];
        const cid = content_hashUtils.decode(hash);
        item.ipfsUrl = IPFS_PREVIEW_URL + cid;
        let ipfsRes = null;
        try {
          ipfsRes = await axios.get(item.ipfsUrl).catch();
          item.nft_data = ipfsRes.data;
          item.nft_data_object = ipfsRes.data;
          item.nft_image = item.nft_data_object.image;
          item.pledgeAmount = this.getPledgeAmount(
            item.nft_data_object.attributes
          );
        } catch (error) {
          item.nft_data = {};
          item.nft_data_object = {};
          item.nft_image = "";
        }
        if(!this.supportNFTUNSELL) {
            const {code, data} = await nftIsSell(item.id, item.chain_id).catch();
            if(code === 200){
            const {selling} = data;
            item.isSelling = selling;
            }
        }
      }
      this.updateNFTItem(item,index)
    },
    async fetchNftList() {
      this.total = 0
      this.nftPages.pageSize = 10;
      const address = this.account;//this.walletData.getAddress();
      this.loadingNft = true;

      const res = await fetchNftList({
        page_no: this.nftPages.current,
        page_size: this.nftPages.pageSize,
        address: this.queryAddress || address,
        // chain_id: chainId,
        // layer,
        // chain_layer_list,
        // chain_layer_list: this.filter.network || this.networkChainLayer,
        chain_layer_list: this.networkAndLayer,
        search: this.filter.search,
        oldest: this.filter.oldest,
      }).catch(e => null);
      console.log("fetchNftList res:", res);
      this.loadingNft = false;
      if (!res) return
      this.nftPages.total = res.data.total;
      this.total = res.data.total;
      this.nftPages.hasNextPage =
        res.data.total > this.nftPages.current * this.nftPages.pageSize;
      this.finishedNft = !this.nftPages.hasNextPage;
      let nftList = [];

      const baseData = [];

      for (let i = 0; i < res.data.nfts.length; i++) {
        baseData[i] = {
          account: "",
          chain_id: null,
          contract: "",
          contract_name: "",
          create_time: "",
          id: null,
          layer: null,
          nft_data: "",
          nft_data_object: {
            attributes: [],
            description: "",
            explicit: false,
            external_link: "",
            image: "",
            levels: [],
            name: "",
            nft_id: null,
            stats: [],
            unlockable_content: "",
          },
          nft_id: null,
          nft_image: "",
          nft_name: null,
          loaded: false,
          isSelling:true,
        };
      }

      this.nftList = baseData;

      const { nfts } = res.data;
      for (let i = 0; i < nfts.length; i++) {
        const item = nfts[i];
        this.getOneNFTDetail(item, i);
      }

      if (isMobile()) {
        if (this.nftPages.current === 1) {
          this.nftList = nftList;
        } else {
          this.nftList = this.nftList.concat(nftList);
        }
      } else {
        //this.nftList = nftList;
      }

    },

    onLoadNft() {
      // console.log('onLoadNft');
      if (this.loadingNft || !this.nftPages.hasNextPage) return;
      this.nftPages.current++;
      this.fetchNftList();
    },
    getPledgeAmount(data) {
      const obj = data.find(
        (item) => item.trait_type === "tokenCoinAmount"
      );
      return obj && obj.value;
    },
    goNFTAuction(item) {
      if (!this.isSupportNFTMarket || this.supportNFTUNSELL) {
        return;
      }
      const owner = this.queryAddress || this.$store.state.user.userInfo.uid;
      const { content_hash } = item;

      this.$router.push({
        path: `/nft/auction`,
        query: {
          id: item.id,
          chainId: item.chain_id,
          layer: 'L'+ item.layer,

          owner,
          contentHash: content_hash,
        },
      });
    },
    goNFTDetail(item) {
      if(this.supportNFTUNSELL) return
      console.log('item:', item);
      this.$router.push({
        path: `/nft/detail`,
        query: {
          id: item.id,
          chainId: item.chain_id,
          layer: 'L'+ item.layer,
          nftDetail: true,
          contentHash: item.layer === 2 ? item.content_hash : null,
        },
      });
    },
    goCreate() {
      this.$router.push("/wallet/nft/create");
    },
    checkByLayer(layer) {
      this.activeLayer = layer
      this.$store.commit('setChainIdAndLayerCache', this.networkAndLayer)
      this.loadData(5);
    },
    // --TODO:clear
    refreshByNetwork(val) {
      // TODO: refresh
      this.networkAndLayer = val;
      this.$store.commit('setChainIdAndLayerCache', this.networkAndLayer)
      this.loadData(1);
    },
    formatAccount(value) {
      return value.slice(0, 4) + "******" + value.slice(-4, value.length);
    },
    copy() {
      let text = this.account || ''; //this.walletData.getAddress() || ''
      copy(text)
      this.$message.success({
        content: this.$t('common_base_copied'),
        closeOnClick: true,
        duration: 5
      })
    },
    formatDataFirst() {
      this.setNetworkList()
    },
    // 获取网络记忆，store获取 > walletChaId > networks[0]
    getSupporNetwork(chainIdAndLayerCache) {
      let walletChainId = this.originChainId || this.networks[0].chainId;
      // let walletChainId = this.$store.state.wallet.walletChainId || this.originChainId || this.networks[0].chainId;
      // if (this.originChainId != this.$store.state.wallet.walletChainId) {
      //     this.$store.commit('setChainId', this.$store.state.wallet.walletChainId)
      // }
      return chainIdAndLayerCache && this.networks.find(v => v.value == chainIdAndLayerCache) || this.networks.find(v => v.chainId == walletChainId) || this.networks[0]
    },
    refreshByImport() {
      this.importTokenVisible = false;
      this.loadData('refresh');
    },
    showImportTokenModal () {
      this.importTokenVisible = true
      this.$nextTick(()=> {
        this.$refs.importToken.reset()
      })
    }
  },
  beforeDestroy() {
    this.$store.commit('setChainIdAndLayerCache', '')
    this.clearPriceInterval();
  },
  destroyed() {
    this.refreshChainId = "";
    this.queryAddress = null;
  },
  filters: {
    hideRate(value, showValue) {
      if (showValue) {
        return value;
      } else {
        return "******";
      }
    },
    masking(address) {
      if (!address || typeof address !== "string") {
        return address;
      }

      return `${address.substring(0, 4)}...${address.substring(
        address.length - 4,
        address.length
      )}`;
    },
  },
};
